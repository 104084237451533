var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "KnowledgeLibraryManageList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportUrl: _vm.exportURL,
          exportMethod: "get",
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
          isMultiSelect: true,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.createFile },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tableLeftSlot",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.treeShow,
                        expression: "treeShow",
                      },
                    ],
                    staticClass: "table-left",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tree-search" },
                      [
                        _c("v-input", {
                          attrs: {
                            "prefix-icon": "el-icon-search",
                            placeholder: "请输入名称",
                          },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tree-title" },
                      [
                        _c("div", { staticClass: "title" }, [_vm._v("目录")]),
                        _c("v-button", {
                          attrs: { text: "新增", permission: "addSectionBtn" },
                          on: { click: _vm.createOrg },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tree-group" },
                      [
                        _c("button-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.treeLoading,
                              expression: "treeLoading",
                            },
                          ],
                          key: _vm.keyTree,
                          ref: "buttonTree",
                          attrs: {
                            data: _vm.treeData,
                            defaultProps: _vm.treeProps,
                            btnOps: _vm.btnOps,
                            filterText: _vm.filterText,
                            filterNodeMethod: _vm.filterNodeMethod,
                            defaultExpandedKeys: _vm.defaultExpandedKeys,
                            currentNodeKey: _vm.currentNodeKey,
                            highlightCurrent: true,
                            "expand-on-click-node": false,
                          },
                          on: {
                            buttonClick: _vm.buttonNode,
                            nodeClick: _vm.nodeClick,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "treeSwith", on: { click: _vm.treeSwith } },
                  [
                    _vm.treeShow
                      ? _c("img", { attrs: { src: _vm.treeOffImgSrc } })
                      : _vm._e(),
                    !_vm.treeShow
                      ? _c("img", { attrs: { src: _vm.treeOnImgSrc } })
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "文档名称", placeholder: "请输入文档名称" },
                  model: {
                    value: _vm.searchParams.fileName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "fileName", $$v)
                    },
                    expression: "searchParams.fileName",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "发布端口",
                    placeholder: "请选择发布端口",
                    options: _vm.portOps,
                  },
                  model: {
                    value: _vm.searchParams.platform,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "platform", $$v)
                    },
                    expression: "searchParams.platform",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "标签" },
                      model: {
                        value: _vm.searchParams.labelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "labelId", $$v)
                        },
                        expression: "searchParams.labelId",
                      },
                    },
                    "v-select2",
                    _vm.labelParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { permission: "edit", text: "编辑", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { permission: "view", text: "查看", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.view(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    type: "text",
                    permission: "release",
                    text: scope.row.publishStatus === 0 ? "发布" : "再次发布",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.release(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { permission: "delete", text: "删除", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.remove(scope.row)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "批量删除", permission: "batchDelete" },
                  on: {
                    click: function ($event) {
                      return _vm.batchRemove(scope.selectedData)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "批量移动", permission: "batchMove" },
                  on: {
                    click: function ($event) {
                      return _vm.batchMove(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-tree__node",
          attrs: {
            title: _vm.dialogText,
            width: "540px",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              key: _vm.key,
              ref: "form",
              attrs: { "label-width": "100px", model: _vm.form },
            },
            [
              _vm.dialogValue === 1 || _vm.dialogValue === 2
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "文件夹名称",
                          prop: "orgName",
                          rules: [
                            {
                              required: true,
                              message: "请输入文件夹名称",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入文件夹名称",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.orgName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "orgName", $$v)
                            },
                            expression: "form.orgName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 3
                ? [
                    _c("p", { staticClass: "p-mar-0" }, [
                      _vm._v("确定删除文件夹："),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentData &&
                              _vm.currentData[_vm.treeProps.label]
                          )
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.dialogValue === 4
                ? [
                    _c("p", { staticClass: "p-text p-pad-l" }, [
                      _vm._v("文件夹 "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentData &&
                              _vm.currentData[_vm.treeProps.label]
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "移动至",
                          prop: "parentId",
                          rules: [
                            {
                              required: true,
                              message: "请选择文件夹",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("select-tree", {
                          attrs: {
                            value: _vm.form.parentId,
                            placeholder: "查询文件夹",
                            treeData: _vm.treeData,
                            treeProps: _vm.treeProps,
                            highlightCurrent: true,
                            filterNodeMethod: _vm.filterNodeMethod,
                            "expand-on-click-node": false,
                            width: _vm.width,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.form, "parentId", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 5
                ? [
                    _c("p", { staticClass: "p-text p-pad-l" }, [
                      _vm._v("所有文件共" + _vm._s(_vm.ids.length) + "个"),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "移动至",
                          prop: "parentId",
                          rules: [
                            {
                              required: true,
                              message: "请选择文件夹",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("select-tree", {
                          attrs: {
                            value: _vm.form.parentId,
                            data: _vm.treeOrgData,
                            placeholder: "查询文件夹",
                            treeData: _vm.treeData,
                            treeProps: _vm.treeProps,
                            highlightCurrent: true,
                            filterNodeMethod: _vm.filterNodeMethod,
                            "expand-on-click-node": false,
                            width: _vm.width,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.form, "parentId", $event)
                            },
                            "update:data": function ($event) {
                              _vm.treeOrgData = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.dialogValue === 6
                ? [
                    _c("p", { staticClass: "p-mar-0" }, [
                      _vm._v("文件夹 "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.currentData[_vm.treeProps.label])),
                      ]),
                      _vm._v(
                        " 下存在文件，如需删除该文件夹，请移除文件夹下文件"
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.dialogValue !== 7
                ? _c("v-button", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { text: "取 消" },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  })
                : _vm._e(),
              _c("v-button", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { type: "primary", text: "确 定" },
                on: { click: _vm.clickCheck },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }