<template>
  <div class="KnowledgeLibraryManageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :exportUrl="exportURL"
      :exportMethod="'get'"
      :searchUrl="searchUrl"
      :searchParams="searchParams"
      :extraParams="extraParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="createFile"></v-button>
      </template>
      <template #tableLeftSlot>
        <div class="table-left" v-show="treeShow">
          <!-- 搜索框 -->
          <div class="tree-search">
            <v-input v-model="filterText" prefix-icon="el-icon-search" placeholder="请输入名称" />
          </div>
          <!-- 目录 -->
          <div class="tree-title">
            <div class="title">目录</div>
            <v-button text="新增" permission="addSectionBtn" @click="createOrg"></v-button>
          </div>
          <!-- 控件树 -->
          <div class="tree-group">
            <button-tree
              ref="buttonTree"
              v-loading="treeLoading"
              :key="keyTree"
              :data="treeData"
              :defaultProps="treeProps"
              :btnOps="btnOps"
              :filterText="filterText"
              :filterNodeMethod="filterNodeMethod"
              :defaultExpandedKeys="defaultExpandedKeys"
              :currentNodeKey="currentNodeKey"
              :highlightCurrent="true"
              :expand-on-click-node="false"
              @buttonClick="buttonNode"
              @nodeClick="nodeClick"
            />
          </div>
        </div>
        <div class="treeSwith" @click="treeSwith">
          <img v-if="treeShow" :src="treeOffImgSrc" />
          <img v-if="!treeShow" :src="treeOnImgSrc" />
        </div>
      </template>
      <template #searchSlot>
        <v-input label="文档名称" v-model="searchParams.fileName" placeholder="请输入文档名称" />
        <v-select label="发布端口" placeholder="请选择发布端口" v-model="searchParams.platform" :options="portOps" />
        <v-select2
          label="标签"
          v-model="searchParams.labelId"
          v-bind="labelParams"
        />
      </template>
      <template #operateSlot="scope">
        <v-button permission="edit" text="编辑" type="text" @click="edit(scope.row)" />
        <v-button permission="view" text="查看" type="text" @click="view(scope.row)" />
        <v-button
          type="text"
          permission="release"
          :text="scope.row.publishStatus === 0 ? '发布' : '再次发布'"
          @click="release(scope.row)"
        />
        <v-button permission="delete" text="删除" type="text" @click="remove(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除" permission="batchDelete" @click="batchRemove(scope.selectedData)"></v-button>
        <v-button text="批量移动" permission="batchMove" @click="batchMove(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog class="dialog-tree__node" :title="dialogText" width="540px" :visible.sync="dialogVisible">
      <el-form ref="form" label-width="100px" :model="form" :key="key">
        <!-- 添加或编辑文件夹 -->
        <template v-if="dialogValue === 1 || dialogValue === 2">
          <el-form-item
            label="文件夹名称"
            prop="orgName"
            :rules="[ { required: true, message: '请输入文件夹名称', trigger: 'blur' } ]"
          >
            <v-input placeholder="请输入文件夹名称" v-model="form.orgName" :width="width"/>
          </el-form-item>
        </template>
        <!-- 删除组织节点下文件 -->
        <template v-if="dialogValue === 3">
          <p class="p-mar-0">确定删除文件夹：<span style="color:red;">{{currentData && currentData[treeProps.label]}}</span></p>
        </template>
        <!-- 移动组织节点 -->
        <template v-if="dialogValue === 4">
          <p class="p-text p-pad-l">文件夹&nbsp;<span style="color:red;">{{currentData && currentData[treeProps.label]}}</span></p>
          <el-form-item
            label="移动至"
            prop="parentId"
            :rules="[ { required: true, message: '请选择文件夹', trigger: 'blur' } ]"
          >
            <select-tree
              :value.sync="form.parentId"
              placeholder="查询文件夹"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
              :width="width"
            />
          </el-form-item>
        </template>
        <!-- 批量移动人员 -->
        <template v-if="dialogValue === 5">
          <p class="p-text p-pad-l">所有文件共{{ids.length}}个</p>
          <el-form-item
            label="移动至"
            prop="parentId"
            :rules="[ { required: true, message: '请选择文件夹', trigger: 'blur' } ]"
          >
            <select-tree
              :value.sync="form.parentId"
              :data.sync="treeOrgData"
              placeholder="查询文件夹"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
              :width="width"
            />
          </el-form-item>
        </template>
        <!-- 删除组织节点 -->
        <template v-if="dialogValue === 6">
          <p class="p-mar-0">文件夹&nbsp;<span style="color:red;">{{currentData[treeProps.label]}}</span>&nbsp;下存在文件，如需删除该文件夹，请移除文件夹下文件</p>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <v-button @click="dialogVisible = false" style="margin-right:10px" text="取 消" v-if="dialogValue !== 7"></v-button>
        <v-button type="primary" v-loading="loading" @click="clickCheck" text="确 定"></v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEnterOrgTreeListURL,
  addOrgNodeURL,
  updateOrgNodeURL,
  batchMoveURL,
  updateOrgInformationURL,
  searchFileNumURL,
  deleOrgNodeURL,
  selectEnterpriseUserInfoListURL,
  getLabelURL,
  exportURL,
  batchDeleteURL,
  releaseURL
} from './api'
import {
  portOps,
  portMap,
  statusOps,
  statusMap,
  btnOps
} from './map'
import { ButtonTree, SelectTree } from 'components/bussiness/index'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'

export default {
  name: 'KnowledgeLibraryManageList',
  components: {
    'button-tree': ButtonTree,
    'select-tree': SelectTree
  },
  data () {
    return {
      width: 320,
      exportURL,
      searchUrl: selectEnterpriseUserInfoListURL,
      portOps: portOps(1),
      portMap,
      statusOps: statusOps(1),
      statusMap,
      labelParams: {
        searchUrl: getLabelURL,
        subjoin: {
          labelType: 3
        },
        request: {
          text: 'labelName',
          value: 'id'
        },
        response: {
          text: 'labelName',
          value: 'labelId'
        }
      },
      searchParams: {
        documentId: '',
        platform: undefined,
        labelId: '',
        fileName: ''
      },
      extraParams: {
      },
      headers: [
        {
          prop: 'fileName',
          label: '名称'
        },
        {
          prop: 'level',
          label: '层级'
        },
        {
          prop: 'platform',
          label: '发布端',
          formatter (row) {
            if (row.platform) {
              let arr = row.platform.split(',')
              return arr.reduce((pre, cur) => {
                return pre + ',' + portMap[cur]
              }, '').substring(1)
            }
            return ''
          }
        },
        {
          prop: 'labelList',
          label: '标签',
          formatter (row) {
            const arr = row.labelList || []
            return arr.map(v => v.labelName).join(',')
          }
        },
        {
          prop: 'publishTime',
          label: '发布时间'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        },
        {
          prop: 'publishUser',
          label: '发布人'
        },
        {
          prop: 'publishStatus',
          label: '发布状态',
          formatter (row) {
            return statusMap[row.publishStatus]
          }
        }
      ],
      // tree
      type: 0, // typeTree 改变的方式 1 表示通过按钮改变
      keyTree: 0,
      treeLoading: false,
      currentData: null, // 按钮点击时保存的节点数据
      currentItem: null, // 按钮点击时保存的按钮数据
      treeOnImgSrc,
      treeOffImgSrc,
      treeShow: true,
      filterText: '',
      btnOps,
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeOrgData: {}, // select-button值对应的数据
      currentNodeKey: '',
      defaultExpandedKeys: [],
      // 弹框
      ids: [], // 记录批量移动人员的ids
      loading: false,
      key: 0,
      form: {
        orgId: '',
        orgName: '',
        parentId: ''
      },
      dialogValue: '',
      dialogText: '',
      dialogVisible: false
    }
  },
  watch: {
    dialogVisible (val) {
      this.key += 1
      if (!val) {
        // 弹窗关闭时，需要调一次列表接口
        this.form = {
          orgId: '',
          parentId: '',
          orgName: ''
        }
      }
    },
    currentNodeKey (val, old) {
      // 1. 获取当前选中的key
      this.$set(this.searchParams, 'documentId', val)
      // console.log('currentNodeKey', `${val}----${old}`)
      // 2. 弹框状态和该组件更新时导致 currentNodeKey 的改变不触发查询列表
      if (!this.dialogVisible && this.type !== 1) {
        // console.log('此处触发1', this.searchParams)
        this.$refs.list.searchData(this.searchParams)
      }
      this.type = 0
    }
  },
  created () {
  },
  mounted () {
    const documentId = sessionStorage.getItem('documentId')
    if (documentId) {
      this.type = 1 // 当组件更新导致的 currentNodeKey 变化,不触发列表的查询
      this.defaultExpandedKeys = documentId ? [documentId] : []
      this.currentNodeKey = documentId
      this.searchParams.documentId = documentId // 默认调取一次列表接口时，参数确保正确
      sessionStorage.removeItem('documentId')
    }
    this.getTreeList()
    this.$setBreadcrumb('问答管理')
  },
  methods: {
    createFile () {
      this.$router.push({
        name: 'knowledgeLibraryManageForm',
        query: {
          documentId: this.currentNodeKey
        }
      })
    },
    edit (row) { // 编辑按钮
      this.$router.push({
        name: 'knowledgeLibraryManageForm',
        query: {
          id: row.id,
          documentId: this.currentNodeKey
        }
      })
    },
    view (row) {
      this.$router.push({
        name: 'knowledgeLibraryManageView',
        query: {
          id: row.id,
          documentId: this.currentNodeKey
        }
      })
    },
    release (row) { // 发布
      let _this = this
      const params = {
        id: row.id,
        publishStatus: 1
      }
      _this.$axios({
        method: 'get',
        url: releaseURL,
        params
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    async remove (row) { // 删除按钮
      let msg = '确认删除该文件？'
      let isOk = await this.$confirm(msg)
      isOk && this.removeRequest([row.id])
    },
    async batchRemove ({ data }) { // 批量删除
      const ids = data.map(v => v.id)
      if (ids.length === 0) {
        this.$message('请选择文件')
        return
      }
      let isOk = await this.$confirm(`确认关闭所选文件共${ids.length}个？`)
      let _ids = ids
      isOk && this.removeRequest(_ids)
    },
    removeRequest (ids) {
      this.$axios({
        method: 'post',
        url: batchDeleteURL,
        data: ids
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            center: true
          })
        }
      })
    },
    batchMove ({ ids }) { // 批量移动
      this.ids = ids
      if (ids.length === 0) {
        this.$message('请选择文件')
        return
      }
      this.dialogVisible = true
      this.dialogValue = 5
    },

    /** 树节点相关 */
    createOrg () { // 新增
      this.dialogText = '添加文件夹'
      this.dialogValue = 1
      this.dialogVisible = true
      this.currentData = null
      this.currentItem = null
    },
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(getEnterOrgTreeListURL)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    treeSwith () { // 树的显示隐藏
      this.treeShow = !this.treeShow
    },
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    buttonNode (data, item) { // 点击树节点的弹框按钮
      // data 为节点对象的数据, item 为按钮的对象数据
      this.currentData = data
      this.currentItem = item
      if (item.title) {
        this.dialogText = item.title
        this.dialogValue = item.code
        this.dialogVisible = true
      }
      // 以下为按钮的特殊需求
      if (item.value === 'editBtn') { // 当点击编辑按钮时
        this.form.orgName = this.currentData[this.treeProps.label]
      }
    },
    nodeClick (data, node, nodeVue) { // 点击树节点文字
      /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      const val = data && (data[this.treeProps.id] || undefined)
      this.defaultExpandedKeys = val ? [val] : []
      this.currentNodeKey = val
    },

    /** 弹框相关 */
    async clickCheck () {
      let onOff = true
      this.$refs.form.validate(result => {
        onOff = result
      })
      if (!onOff) {
        return
      }
      this.loading = true
      let result = false
      // 点击确认,总共6种情况 1添加组织节点 2查询组织节点下成员 3编辑组织节点 4移动组织节点 5删除公司 6批量移动 7删除组织节点
      if (this.dialogValue === 1) {
        result = await this.addOrgNode()
      } else if (this.dialogValue === 2) {
        result = await this.editOrgNode()
      } else if (this.dialogValue === 3) {
        result = await this.searchDeteOrgNode()
      } else if (this.dialogValue === 4) {
        result = await this.moveOrgNode()
      } else if (this.dialogValue === 5) {
        result = await this.batchMoveFile()
      } else if (this.dialogValue === 6) {
        result = true
      }
      this.loading = false
      if (result) {
        this.dialogVisible = false
        this.$refs.list.searchData()
      }
    },
    async addOrgNode () {
      let isSuccess = false
      await this.$axios.post(addOrgNodeURL, {
        orgName: this.form.orgName,
        parentId: this.currentData ? this.currentData[this.treeProps.id] : ''
      }).then((res) => {
        if (res.status === 100 && res.msg === 'ok') {
          isSuccess = true
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          this.defaultExpandedKeys = val ? [val] : []
          this.currentNodeKey = val
          this.getTreeList()
        }
      })
      return isSuccess
    },
    async editOrgNode () {
      let isSuccess = false
      await this.$axios.post(updateOrgInformationURL, {
        id: this.currentData[this.treeProps.id],
        orgName: this.form.orgName
      }).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          const parentVal = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
          this.defaultExpandedKeys = parentVal ? [parentVal] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    },
    async searchDeteOrgNode () { // 文件夹下是否存在文件
      let isSuccess = false
      // 先查是否存在文件
      await this.$axios.get(`${searchFileNumURL}?id=${this.currentData[this.treeProps.id]}`)
        .then(async (res) => {
          if (res.status === 100 && res.msg === 'ok') {
            if (res.data === 0) {
              isSuccess = await this.deteOrgNode()
            } else {
              this.dialogValue = 6
            }
          }
        })
      return isSuccess
    },
    async deteOrgNode () {
      let isSuccess = false
      await this.$axios.post(deleOrgNodeURL, { id: this.currentData[this.treeProps.id] })
        .then(res => {
          if (res.status === 100 && res.msg === 'ok') {
            const val = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
            this.defaultExpandedKeys = val ? [val] : []
            this.currentNodeKey = val
            this.getTreeList()
            isSuccess = true
          }
        })
      return isSuccess
    },
    async moveOrgNode () {
      let isSuccess = false
      await this.$axios.post(updateOrgNodeURL, {
        id: this.currentData[this.treeProps.id],
        parentId: this.form.parentId
      }).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          this.defaultExpandedKeys = val ? [val] : []
          // this.defaultExpandedKeys = this.form.parentId ? [this.form.parentId] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    },
    async batchMoveFile () {
      let isSuccess = false
      await this.$axios.post(batchMoveURL, {
        ids: this.ids,
        orgId: this.form.parentId
      }).then(res => {
        if (res.status === 100 && res.msg === 'ok') {
          this.currentData = this.treeOrgData
          const val = this.currentData && (this.currentData[this.treeProps.id] || undefined)
          const parentVal = this.currentData && (this.currentData[this.treeProps.parentId] || undefined)
          this.defaultExpandedKeys = parentVal ? [parentVal] : []
          this.currentNodeKey = val
          this.getTreeList()
          isSuccess = true
        }
      })
      return isSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
.KnowledgeLibraryManageList-wrapper {
  .table-left {
    height: 100%;
    display: inline-block;
    position: relative;
    .tree-search {
      text-align: center;
      ::v-deep .v-control {
        width: 100%;
        .el-input {
          width: 100% !important;
          .el-input__inner {
            outline: none;
            padding: 2px 10px 2px 30px;
            height: 33px;
          }
          .el-input__icon {
            line-height: 33px;
          }
          .el-input__suffix {
            line-height: 33px;
          }
        }
      }
    }
    ::v-deep .el-loading-mask {
      z-index: 100!important;
    }
    .tree-title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      display: flex;
      z-index: 200;
      .title {
        flex: 1;
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        padding-left: 4px;
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
      .tree-select__list {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 200;
        > li {
          cursor: pointer;
          padding: 0px 12px;
          color: #909399;
          &:hover {
            color: #1B8CF2;
            background-color: #f5f7fa;
          }
        }
      }
    }
    .tree-group {
      margin-top: 40px;
      height: calc(100% - 73px);
      width: 240px;
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .dialog-tree__node {
    text-align: left;
    .p-text {
      margin-top: 0;
    }
    .p-mar-0 {
      margin: 0;
    }
    .p-pad-l {
      padding-left: 45px;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
